






































import { Component, Vue } from "vue-property-decorator";
import { guideServer } from "../../../assets/js/Request";
import calcKeyByRSA from "../../../assets/js/CommonFun";
import { Toast } from "vant";
import { mapMutations } from "vuex"
@Component
export default class SGAdminLogin extends Vue {
  private phone = "";
  private verification_code = "";
  private verifyAble = true;
  private time = 60;

  verify(): void {
    let data = {
      phone: this.phone,
    };
    if (!this.phone) {
      this.$message({
        message: "请输入手机号",
        type: "warning",
      });
    } else {
      if (!this.phone.match(/^1[3|4|7|5|8|6|9][0-9]\d{4,8}$/)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      this.$post(guideServer + "/shopping_guide/send_sms_code", data, {
        //请求头中加验签
        headers: { sign: calcKeyByRSA.calcKeyByRSA(data) },
      }).then((res: any) => {
        let data = res.data;
        if (data.status == 1) {
          this.$message({
            message: "验证码发送成功",
            type: "success",
          });
          this.timeCount();
          this.verifyAble = false;
        } else {
          this.$message({
            message: "请输入正确的账号",
            type: "warning",
          });
        }
      });
    }
  }
  
  login(): void {
    let data = {
      phone: this.phone,
      verification_code: this.verification_code,
    };
    if (!this.phone) {
      this.$message({
        message: "请输入手机号",
        type: "warning",
      });
    } else if (!this.verification_code) {
      this.$message({
        message: "请输验证码",
        type: "warning",
      });
    } else {
      this.$post(guideServer + "/shopping_guide/login", data, {
        //请求头中加验签
        headers: { sign: calcKeyByRSA.calcKeyByRSA(data) },
      }).then((res: any) => {
        // console.log(res)
        let userInfo = res.data.data.data
        // console.log(userInfo)
        if (res.data.status == 1) {
          //用户信息存本地
          // localStorage.setItem("userInfo",JSON.stringify(userInfo));
          //用户信息传到仓库
          this.$store.commit("mSetTokenInfo",userInfo.token)
          this.$store.commit("mSetRefreshTtoken",userInfo.refresh_token)
          // localStorage.setItem("token", userInfo.token);
          this.$router.push({ name: "SG-AdminIndex" });
          Toast("登录成功!");
          this.phone = "";
          this.verification_code = "";
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    }
  }

  timeCount(): void {
    if (this.time) {
      this.time -= 1;
      setTimeout(() => {
        this.timeCount();
      }, 1000);
    } else {
      this.verifyAble = true;
      this.time = 60;
    }
  }
}
